import React from "react";
import Layout from "../components/Layout";

export default function flipbook() {
  return (
    <Layout
      pageTitle={`2022 Flipbook`}
      ogUrl="/flipbook/"
      pageDecription={"Philips Academy 2022 Flipbook"}
      bodyOnly
    >
      <div style={{ height: "100vh", width: "100vw" }}>
        <iframe
          title="Flipbook"
          src="https://philips-academy-flipbook.netlify.app/"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      </div>
    </Layout>
  );
}
